<template>
  <div class="content">
    <heads></heads>
    <div class="banner">
      <div class="banner-flex">
        <div class="banner-flex-tit">{{ $t('market.dsj')}}</div>
        <div class="banner-flex-tit2">{{ $t('market.wj')}}</div>
      </div>
    </div>
    <div class="big-box">
      <div class="box" v-for="(item,index) in getData" v-if="index<=2">
        <div>
          <div class="box-usdt">{{ item.coin_name }}/USDT <span class="green" :class="{'red':item.change_num<0}">{{ item.change_num >= 0 ? '+' : '' }}{{ (item.change_num * 1).toFixed(2) }}%</span></div>
          <div class="box-num">{{ (item.coin_price * 1).toFixed(item.coin_price>1000?2:6) }}</div>
          <div class="box-num2">24H Vol {{ (item.make_num * 1).toFixed(item.make_num>1000?2:6) }}</div>
        </div>
        <div>
            <img class="box-img" src="../../assets/market/d.png" alt="">
        </div>
      </div>

    </div>
    <div class="quotation-box">
      <div class="quotation-box-head">
        <div style="display: flex;align-items: center;">
          <div @click="type=1" class="quotation-box-nav" :class="type===1?'quotation-active':''" style="margin-right:156px;">
            <div class="quotation-box-tit">{{ $t('market.xhjy')}}</div>
          </div>
          <div @click="type=2" class="quotation-box-nav" :class="type===2?'quotation-active':''">
            <div class="quotation-box-tit">{{ $t('market.qhjy')}}</div>
          </div>
        </div>
        <div class="kuang">
          <input type="text" v-model="name" :placeholder="$t('market.ss')">
          <img class="search-img" src="../../assets/market/ss.png" alt="">
        </div>
      </div>
      <div class="quotation-list">
        <div class="quotation-list-tit">
          <div>{{ $t('market.jyd')}}</div>
          <div>{{ $t('market.zxj')}}</div>
          <div>{{ $t('market.zdf')}}</div>
          <div>24H {{ $t('market.zgj')}}</div>
          <div>24H {{ $t('market.zdj')}}</div>
          <div>24H {{ $t('market.cjl')}}</div>
          <div style="text-align: center;">{{ $t('market.cz')}}</div>
        </div>
      </div>
      <div class="quotation-list" v-for="(item,index) in list" :key="index" >
        <div class="quotation-list-tit">
          <div class="fs-16"><span style="color: #FFFFFF;">{{ item.coin_name }}</span> /USDT</div>
          <div style="color: #FFFFFF;">{{ (item.coin_price * 1).toFixed(item.coin_price>1000?2:6) }}</div>
          <div class="green" :class="{'red':item.change_num<0}">{{ item.change_num >= 0 ? '+' : '' }}{{ (item.change_num * 1).toFixed(2) }}%</div>
          <div style="color: #FFFFFF;">{{ (item.max_price * 1).toFixed(item.max_price>1000?2:6) }}</div>
          <div style="color: #FFFFFF;">{{ (item.mix_price * 1).toFixed(item.mix_price>1000?2:6) }}</div>
          <div style="color: #FFFFFF;">{{ (item.make_num * 1).toFixed(item.make_num>1000?2:6) }}</div>
          <div class="intro-flex">
            <div class="intro" @click="toJy(item)">{{ $t('market.jy')}}</div>
          </div>

        </div>
      </div>
    </div>
    <!-- <footerb></footerb> -->
  </div>
</template>

<script>
  import heads from '../../components/header.vue'
  import footerb from '../../components/footer.vue'
  import {getIconPriceList} from "@/api/deal";
  export default {
    data() {
      return {
        list:[],
        getData: [],
        name: '',
        type:1, //1 BB 2 期货
      }
    },
    components: {
      footerb,
      heads
    },
    watch:{
      'name'(val){
        this.setList(val,this.getData);
      },
    },
    mounted() {
      this.getIconPriceList()
      document.addEventListener("newCoinPrice",(e)=>{
        if(e.detail){
          this.getData = e.detail
          this.setList(this.name,e.detail);
        }
      })
    },
    methods: {
      setList(text,arr){
        if(!text){
          this.list = this.getData
        }else{
          let list = [];
          arr.forEach((v)=>{
            if(v.coin_name.indexOf(text)!=-1){
              list.push(v)
            }
          })
          this.list = list
        }
      },

      getIconPriceList() {
        getIconPriceList().then(res => {
          if (res.code === 1) {
            this.getData = res.data
            this.list = this.getData
          }
        })
      },
      toJy(item){
        if(this.type === 1){
            this.$router.push({path:`/transaction?id=${item.id}&coin_name=${item.coin_name}`})
        }else{
          this.$router.push({path:`/futures?id=${item.id}&coin_name=${item.coin_name}&coin_price=${item.coin_price}`})
        }
      },
    }
  };

</script>
<style scoped>
  html {
    background: #171e28;
  }
  .fs-16{
    font-size: 16px;
  }
  .banner {
    width: 100%;
    height: 440px;
    background: url('../../assets/market/banner.png') no-repeat;
    background-position: center center;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .banner-flex{
    margin-left: 90px;
    color: #FFFFFF;
  }
  .banner-flex-tit{
    font-size: 64px;
  }
  .banner-flex-tit2{
    font-size: 100px;

}

  .big-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .box {
    width: 32%;
    height: 182px;
    background: #29343D;
    box-sizing: border-box;
    padding: 34px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }

  .box-usdt {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.8);
    margin-right: 10px;
    margin-bottom: 24px;
  }

  .box-img {
    width: 224px;
    height: 106px;
  }

  .box-num {
    font-size: 32px;
    color: #ffffff;
    margin-bottom: 10px;
  }

  .box-num2 {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
  }

  .quotation-box {
    width: 100%;
    height: auto;
    background: #29343D;
    padding: 28px 0 16px 0;
    margin-bottom: 30px;
  }

  .quotation-box-img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .quotation-box-tit {
    font-size: 16px;
    /* color: #FFFFFF; */
  }

  .quotation-box-nav {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    color: rgba(255, 255, 255, 0.6);
  }

  .quotation-active {
    border-bottom: 1px solid #FFC300;
    color: #FFC300 !important;
  }

  .quotation-box-head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #1F282F;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 0 60px;
    box-sizing: border-box;
  }

  .quotation-list {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    border-bottom: 1px solid #1F282F;
    padding-bottom: 10px;
    margin-bottom: 16px;
  }

  .quotation-list-tit {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 60px;
  }

  .quotation-list-tit div {
    width: 14.28%;
    font-size: 14px;
  }

  .quotation-list-red {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .green {
    color: #4DB871;
  }
  .red{
    color:  rgb(255, 107, 101);
  }

  .intro-flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .intro {
    width: auto !important;
    height: 26px;
    border-radius: 4px;
    padding:0 6px ;
    background: #FFC300;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #212121;
  }

  .kuang {
    width: 250px;
    height: 32px;
    border-radius: 2px;
    border: 1px solid #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
  }

  .kuang input {
    color: #FFFFFF;
    font-size: 12px;
  }

  .search-img {
    width: 20px;
    height: 20px;
  }

</style>
